<template>
	<router-view />
</template>

<style lang="scss">
body,
html {
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

#app {
	background-color: black;
	display: flex;
	flex-direction: column;
	height: 100vh;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

.content {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: var(--padding);
	overflow-x: hidden;
	overflow-y: scroll;
	background-color: #040a1a;
	padding: 16px;
}

.panel {
	border-radius: 10px;
	background-color: #373945;

	margin-bottom: 16px;
	&__heading {
		font-size: 18px;
		font-weight: 500;
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px;
		border-bottom: 1px solid #555b6b;

		svg {
			height: 16px;
		}
	}
}
</style>
