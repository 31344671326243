<template>
	<div class="panel">
		<div class="panel__heading">
			{{ title }}

			<span><LoadingVue v-if="isLoading" />{{ done }}/{{ total }}</span>
		</div>
		<div class="panel__contents">
			<div class="task-list">
				<div
					:class="[
						'task-item',
						`task-item--${element.status}`,
						`task-item--${element.timing}`,
					]"
					v-for="element in tasks"
					:key="element.id"
				>
					<div
						v-if="!hideToggle"
						class="task-item__icon"
						@click="toggleStatus(element.id)"
					>
						<span class="task-item__check"></span>
					</div>
					<div class="task-item__name">
						<router-link :to="`/form?id=${element.id}`">
							{{ element.name }}</router-link
						>
					</div>
					<div class="task-item__avatar-wrapper" v-if="showIcon">
						<img
							:src="`${element.avatar}`"
							class="task-item__avatar"
						/>
					</div>
					<div class="task-item__date">
						{{ element.due_date_short }}
					</div>
				</div>
				<div class="task-list__empty-message" v-if="tasks.length == 0">
					Brak zadań
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LoadingVue from "./LoadingVue.vue";

export default {
	name: "TasklistVue",
	props: {
		title: String,
		type: String,
		showIcon: Boolean,
		hideToggle: Boolean,
	},
	data() {
		return {
			isLoading: false,
			fetchTimeout: null,
			done: 0,
			total: 0,
			tasks: [],
		};
	},

	mounted() {
		this.tasks = this.$cache.get(`tasks_${this.type}`);
		this.fetch(true);
	},
	methods: {
		toggleStatus(id) {
			this.tasks.forEach((task) => {
				if (task.id == id) {
					if (task.status == "todo") {
						task.status = "done";
						this.$confetti.addConfetti({
							confettiRadius: 3,
						});
					} else if (task.status == "done") task.status = "todo";
					this.updateTask(task);
				}
			});
		},

		updateTask(task) {
			this.$axios
				.post(`task/${task.id}/update`, {
					status: task.status,
				})
				.then(() => {
					this.enqueueFetch();
				})
				.catch((error) => {
					alert(error);
				});
		},

		enqueueFetch() {
			clearTimeout(this.fetchTimeout);
			this.fetchTimeout = setTimeout(() => {
				this.fetch(false);
			}, 3000);
		},
		fetch(showLoader) {
			if (showLoader) this.isLoading = true;

			this.$axios
				.get("task/list?type=" + this.type)
				.then((response) => {
					this.done = 0;
					this.total = 0;

					this.tasks = response.data.tasks;
					this.isLoading = false;
					this.total = response.data.tasks.length;
					this.tasks.forEach((task) => {
						if (task.status == "done") {
							this.done++;
						}
					});

					this.$cache.set(`tasks_${this.type}`, response.data.tasks);
				})
				.catch((error) => {
					this.isLoading = false;
					this.$NetworkError(error);
				});
		},
	},

	components: {
		LoadingVue,
	},
};
</script>

<style lang="scss">
.task-list {
	display: flex;
	flex-direction: column;

	&__empty-message {
		padding: 8px 16px;
		color: #555b6b;
		text-align: left;
		font-size: 13px;
		font-style: italic;
		font-weight: bold;
	}
}
.task-item {
	display: flex;
	padding: 8px 16px;
	color: white;
	justify-content: space-between;
	font-size: 14px;
	border-bottom: 1px solid #555b6b;
	&:last-child {
		border-bottom: 0px;
	}

	&__icon {
		width: 32px;
		display: flex;
		align-items: center;
	}

	&__name {
		flex: 1 1 1%;
		padding-right: 8px;
		text-align: left;
		align-items: center;
		line-height: 1.2em;
		display: flex;

		a {
			text-decoration: none;
			color: inherit;
		}
	}

	&__date {
		padding-left: 8px;
		align-content: center;
	}

	&__check {
		display: flex;
		width: 24px;
		height: 24px;
		border: 2px solid #555b6b;
		border-radius: 50%;
		position: relative;
	}

	&__avatar {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 2px solid #555b6b;
	}
	&__avatar-wrapper {
		align-items: center;
	}

	&--done {
		//order: 2;
		opacity: 0.5;
		.task-item__name {
			text-decoration: line-through;
		}

		.task-item__check {
			&:after {
				content: "✓";
				width: 100%;
				height: 100%;
			}
		}
	}

	&--todo {
		//order: 1;
	}

	&--overdue {
		.task-item__date {
			color: red;
		}
	}
}
</style>
