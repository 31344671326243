<template>
	<div class="panel">
		<div class="pane__contents">
			<LoadingVue v-if="isLoading" />
			<form class="form" @submit.prevent="login" v-if="!isLoading">
				<input
					type="text"
					placeholder="Email"
					class="form__input"
					v-model="username"
					autofocus
					required
				/>
				<input
					type="password"
					placeholder="Hasło"
					class="form__input"
					v-model="password"
					required
				/>

				<div class="form__actions">
					<button class="form__submit">→</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import LoadingVue from "./LoadingVue.vue";

export default {
	name: "LoginFormVue",
	data() {
		return {
			isLoading: false,
			username: "",
			password: "",
		};
	},

	methods: {
		async login() {
			try {
				this.isLoading = true;
				const response = await this.$axios.post("user/login", {
					email: this.username,
					password: this.password,
				});
				if (response.data.access_token) {
					localStorage.setItem(
						"accessToken",
						response.data.access_token
					);
					this.$axios.defaults.headers.common["Authorization"] =
						"Bearer " + localStorage.getItem("accessToken");
					this.$router.push("/dashboard");
					this.isLoading = false;
				}
			} catch (error) {
				this.isLoading = false;
				alert(error);
				//this.$NetworkError(error);
			}
		},
	},
	components: {
		LoadingVue,
	},
};
</script>

<style lang="scss">
.task-form {
	padding: 16px;
	margin: 0px;
	&__input {
		width: 100%;
		background-color: #282e3e;
		//background-color:transparent;
		height: 48px;
		border: 0px;
		//   border-bottom:1px solid #555b6b;
		padding: 0px 16px;
		color: white;
		font-size: 14px;

		&:focus {
			outline: none;
		}
	}

	&__actions {
		padding: 8px 0px;
		display: flex;
		justify-content: space-between;
	}
}
</style>
