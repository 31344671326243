const NetworkError = function (error) {
	//handle 401
	if (
		error.response &&
		error.response.status &&
		error.response.status == 401
	) {
		this.$router.push("/login");
	} else {
		console.log(error);
		alert(error);
	}
};

export default NetworkError;
