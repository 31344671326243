<template>
	<section class="navbar">
		<ul class="navbar-menu">
			<li class="navbar-menu__item">
				<router-link to="/dashboard">
					<svg
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle
							cx="12"
							cy="12"
							r="10"
							stroke="#8690ac"
							stroke-width="1.5"
						/>
						<path
							d="M8.5 12.5L10.5 14.5L15.5 9.5"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					Moje zadania</router-link
				>
			</li>
			<li class="navbar-menu__item">
				<router-link to="/form">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						fill="none"
					>
						<circle
							cx="12"
							cy="12"
							r="10"
							stroke="#8690ac"
							stroke-width="1.5"
						/>
						<path
							d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
						/></svg
					>Utwórz</router-link
				>
			</li>
			<li class="navbar-menu__item">
				<router-link to="/list">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M2 5.5L3.21429 7L7.5 3"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M2 12.5L3.21429 14L7.5 10"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M2 19.5L3.21429 21L7.5 17"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M22 19L12 19"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
						/>
						<path
							d="M22 12L12 12"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
						/>
						<path
							d="M22 5L12 5"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
						/>
					</svg>
					Wszystkie</router-link
				>
			</li>

			<li class="navbar-menu__item">
				<a @click="logout">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
						/>
						<path
							d="M10 12H20M20 12L17 9M20 12L17 15"
							stroke="#8690ac"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					Wyloguj</a
				>
			</li>
		</ul>
	</section>
</template>

<script>
export default {
	name: "NavbarVue",
	props: {
		title: String,
	},
	methods: {
		logout(event) {
			event.preventDefault();
			localStorage.setItem("accessToken", null);
			this.$axios.defaults.headers.common["Authorization"] =
				"Bearer " + localStorage.getItem("accessToken");
			this.$router.push("/login");
		},
	},
};
</script>

<style lang="scss">
.navbar {
	height: 64px;
	display: flex;
	padding: 8px;
	gap: 16px;
	position: relative;
	background-color: #282e3e;
}

.navbar-menu {
	display: grid;
	padding: 0px;
	margin: 0px;
	align-items: center;
	width: 100%;
	grid-template-columns: repeat(4, 1fr);
	list-style: none;

	&__item {
		font-size: 12px;
		a {
			text-decoration: none;
			color: #8690ac;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 4px;
		}

		&--create {
			font-size: 30px;
		}
	}

	.router-link-active {
		color: white;

		svg {
			stroke: white;
			* {
				stroke: white;
			}
		}
	}
}
</style>
