<template>
	<TopbarVue title="Logowanie" />
	<section class="content">
		<LoginFormVue />
	</section>
</template>

<script>
import TopbarVue from "@/components/TopbarVue.vue";
import LoginFormVue from "@/components/LoginFormVue.vue";

export default {
	name: "DashboardView",
	components: {
		TopbarVue,
		LoginFormVue,
	},
};
</script>
