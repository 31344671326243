const Cache = {
	get: function (name) {
		let item = localStorage.getItem(name);
		if (item) {
			console.log(`Retrieved data from cache ${name}`);
			return JSON.parse(item);
		}
		console.log(`No cache present for cache ${name}`);
		return [];
	},
	set: function (name, value) {
		let item = JSON.stringify(value);
		localStorage.setItem(name, item);
		console.log(`Set cache for ${name}`);
	},
};

export default Cache;
