<template>
	<section class="content">
		<div class="splash">
			<LoadingVue v-if="isLoading" />
		</div>
	</section>
</template>

<script>
import LoadingVue from "@/components/LoadingVue.vue";

export default {
	data() {
		return {
			isLoading: true,
		};
	},
	components: {
		LoadingVue,
	},
	mounted() {
		if (localStorage.getItem("accessToken")) {
			this.$router.push("/dashboard");
		} else {
			this.$router.push("/login");
		}
	},

	methods: {
		async heartbeat() {
			this.isLoading = true;
			try {
				await this.$axios.get(`user/info`);
				this.$router.push("/dashboard");
			} catch (error) {
				if (error.response.status == 401) {
					this.$router.push("/login");
				}
			}
		},
	},
};
</script>
<style lang="scss">
.splash {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
