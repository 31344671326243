import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/DashboardView.vue";
import FormView from "../views/FormView.vue";
import LoginView from "../views/LoginView.vue";
import ListView from "../views/ListView.vue";
import SplashView from "../views/SplashView";

const routes = [
	{
		path: "/dashboard",
		name: "dashboard",
		component: DashboardView,
	},
	{
		path: "/form",
		name: "form",
		component: FormView,
	},
	{
		path: "/list",
		name: "list",
		component: ListView,
	},
	{
		path: "/login",
		name: "login",
		component: LoginView,
	},
	{
		path: "/",
		name: "splash",
		component: SplashView,
	},
	// {
	//     path: "/about",
	//     name: "about",
	//     // route level code-splitting
	//     // this generates a separate chunk (about.[hash].js) for this route
	//     // which is lazy-loaded when the route is visited.
	//     component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

let previousRoute = null;
router.beforeEach((to, from, next) => {
	previousRoute = from;
	next();
});

router.previousRoute = () => previousRoute;

export default router;
