<template>
	<TopbarVue title="Wszystkie zadania" />
	<section class="content">
		<TasklistVue
			title="Do zrobienia"
			type="todo"
			:showIcon="true"
			:hideToggle="true"
		/>
	</section>
	<NavbarVue />
</template>

<script>
import TopbarVue from "@/components/TopbarVue.vue";
import TasklistVue from "@/components/TasklistVue.vue";
import NavbarVue from "@/components/NavbarVue.vue";

export default {
	name: "ListView",
	components: {
		TopbarVue,
		TasklistVue,
		NavbarVue,
	},
};
</script>
