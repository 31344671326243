<template>
	<section class="topbar">
		<span class="topbar__title" v-if="!isReturn">{{ title }}</span>
		<router-link
			:to="`${previousPath}`"
			class="topbar__title"
			v-if="isReturn"
			>← Wróć</router-link
		>
	</section>
</template>

<script>
export default {
	name: "TopbarVue",
	props: {
		title: String,
		isReturn: Boolean,
	},
	data() {
		return {
			previousPath: "/",
		};
	},
	mounted() {
		if (this.$router.previousRoute().fullPath) {
			this.previousPath = this.$router.previousRoute().fullPath;
		}
	},
};
</script>

<style lang="scss">
.topbar {
	height: 64px;
	padding: 16px;
	background: var(--color-accent);
	display: flex;
	color: white;
	background-color: #282e3e;

	&__title {
		font-size: 18px;
		line-height: 14px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		color: white;
		text-decoration: none;
	}
}
</style>
