<template>
	<TopbarVue title="Zadanie" :isReturn="true" />
	<section class="content">
		<FormVue :id="taskId" />
	</section>
	<NavbarVue />
</template>

<script>
import TopbarVue from "@/components/TopbarVue.vue";
import FormVue from "@/components/FormVue.vue";
export default {
	name: "FormView",
	computed: {
		taskId() {
			return this.$route.query.id || 0;
		},
	},
	components: {
		TopbarVue,
		FormVue,
		// TasklistVue,
	},
};
</script>
