import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import NetworkError from "./plugins/NetworkError";
import "./registerServiceWorker";

import Cache from "./plugins/Cache";

import JSConfetti from "js-confetti";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] =
	"Bearer " + localStorage.getItem("accessToken");
axios.defaults.timeout = 60000;

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$NetworkError = NetworkError;
app.config.globalProperties.$cache = Cache;

const confetti = new JSConfetti();

app.config.globalProperties.$confetti = confetti;
app.use(router).mount("#app");
