<template>
	<TopbarVue title="Moje zadania" />
	<section class="content">
		<TasklistVue title="Dzisiaj" type="today" />
		<TasklistVue title="Jutro" type="tomorow" />
		<TasklistVue title="Pozostałe" type="future" />
	</section>
	<NavbarVue />
</template>

<script>
import TopbarVue from "@/components/TopbarVue.vue";
import TasklistVue from "@/components/TasklistVue.vue";
import NavbarVue from "@/components/NavbarVue.vue";

export default {
	name: "DashboardView",
	components: {
		TopbarVue,
		TasklistVue,
		NavbarVue,
	},
};
</script>
